import React from 'react';
import pic from '../photos/profile.jpg';
import { faInstagram, faTwitter, faFacebook, faPinterest } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from 'styled-components';


const AboutSection = styled.div`
    grid-area: about;
    background-color: white;
    padding: 15px;
`
const Socials = styled.div`
    // background-color: #dccfec;
    // background-color: #b8aac2;
    // box-shadow: 2px 4px #f1f1f1;
    height: 5vh;

    ul {
        list-style-type: none;
    }

    li {
        display: inline-block;
    }

    li a {
        display: block;
        color: black;
        text-align: center;
        padding: 14px 16px;
        text-decoration: none;
        font-variant: small-caps;
        font-size: 17px;
      }

      li a:hover {
        color: white;
        background-color: #b8aac2;
      }
`

const Image = styled.img`
    width: 90%;
    object-fit: cover;
    padding: 10px;
`

const Description = styled.p`
    width: 80%;
    margin: auto;
    padding: 5px;
    line-height: 1.5em;
`

const AboutHeader = styled.p`
    font-family: 'Bodoni Moda', serif';
    font-size: 12px;
    font-variant: small-caps;
    font-spacing: 1.5em;
    padding: 10px;
`

class About extends React.Component {
    render() {
        return(
            <AboutSection>
                <div>
                    <AboutHeader>ABOUT MEGAN</AboutHeader>
                    <Image src={pic} alt="Picture of Megan at the top of Griffith Observatory" />
                    <Description>
                        Hi there! I'm Megan, a pastry chef turned software engineer, and I'm here to share my recipes and trials with you. Hope you enjoy ♥
                    </Description>
                    <Socials>
                        <ul>
                            <li><a href="https://www.instagram.com/petitenutmeg/"><FontAwesomeIcon icon={faInstagram} /></a></li>
                            <li><a href="/"><FontAwesomeIcon icon={faTwitter} /></a></li>
                            <li><a href="/"><FontAwesomeIcon icon={faFacebook} /></a></li>
                            <li><a href="https://www.pinterest.com/petitenutmeg/"><FontAwesomeIcon icon={faPinterest} /></a></li>
                            {/* <li><a href="/"><FontAwesomeIcon icon={faPinterest} /></a></li> */}
                        </ul>
                    </Socials>
                </div>
            </AboutSection>
        )
    }
}

export default About;