import React from 'react';
import About from './About';
import Content from './Content';
import Blog from './Blog';
import AboutPage from './AboutPage';
import RecipeList from './recipes/RecipeList';
import Contact from './Contact';
import { Switch, Route } from 'react-router-dom';

class Main extends React.Component {
    render() {
        return (
            <div className="main">
                <About />
                <Switch>
                    <Route exact path="/">
                        <Content />
                    </Route>
                    <Route path="/about">
                        <AboutPage />
                    </Route>
                    <Route path="/blog">
                        <Blog />
                    </Route>
                    <Route path="/recipes">
                        <RecipeList />
                    </Route>
                    <Route path="/contact">
                        <Contact />
                    </Route>
                </Switch>
            </div>
        )
    }
}

export default Main