import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Main from './components/Main';
import Footer from './components/Footer';
import Instagram from './components/Instagram';

function App() {
  return (
    <div className="App">
      <Navbar />
      {/* <div className="banner"></div> */}
      <Main />
      <Instagram />
      {/* <Footer /> */}
      <div className="copyright">Copyright Petite Nutmeg 2023</div>
    </div>
  );
}

export default App;
