import React from 'react';

function StrawberryShortcake() {
        
    return(
        <div className='recipe-container'>
            <div className='recipe-header'>
                strawberry shortcake
            </div>
            <div className='recipe-tags'>
                seasonal, delicious, strawberries
            </div>
            <div className='recipe-description'>
                Strawberry Shortcake are one of my favorite spring and summer dessert. These biscuits are sky-high, super flakey and buttery. They’re layered with juicy, sweet strawberries and topped with the fluffiest sweet whipped cream.
            </div>
            <div className='recipe-photo'>
                <img src={'https://www.acozykitchen.com/wp-content/uploads/2018/07/StrawberryShortcake-12.jpg'} alt='strawberry shortcake' id="recipe-photo"></img>
            </div>
            <button id="continue">Continue Reading</button>
            {/* <div className='recipe-footer'>
                Footer
            </div> */}
        </div>
        )
    }

export default StrawberryShortcake;