import React from 'react';

class Contact extends React.Component {
    render(){
        return(
            <div>
                <div id="contact-page">
                    <h1>Contact</h1>
                    <p>Interested in contacting me?</p>
                    <p>Feel free to reach out through any socials or at my email: </p>
                </div>
            </div>
        )
    }
}

export default Contact;