import React from 'react';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const Navigation = styled.div`
    background-color: white;
    box-shadow: 2px 4px #f1f1f1;
`

const Items = styled.ul`
    list-style-type: none;
    overflow: hidden;
    margin: auto;
    // background-color: #dccfec;
    height: 5vh;
    font-family: ''Bodoni Moda', serif';

    li {
        display: inline-block;
        float: center;

        a {
            display: block;
            // color: #aca599;
            color: black;
            text-align: center;
            padding: 14px 16px;
            text-decoration: none;
            font-variant: small-caps;
            font-size: 13px;
            letter-spacing: 0.15em;
            // font-weight: 550;
        }

        a:hover {
            // background-color: #b5a9a8;
            background-color: #b8aac2;
            color: white;
        }
    }
`

const Logo = styled.p`
    font-family: ''Bodoni Moda', serif';
    font-size: 50px;
    // color: #dccfec;
    // margin: auto;
    // margin-right: 200px;
    padding: 11px;
`

const Description = styled.p`
    font-family: 'Bodoni Moda', serif';
    font-size: 20px;
    color: #d1d1d1;
    margin-top: -60px;
    margin-left: 189px;
`
const Break = styled.div`
    padding: 25px;
`
  
//   #search {
// background-color: #dccfec;
// color: white;
//   }
  
//   #search:hover {
// color: #b8aac2;
//  background-color: white;
//   }

class Navbar extends React.Component {
    render() {
        return (
            <Navigation>
                <Logo>petite nutmeg</Logo>
                <Items>
                    {/* <li style={{color: "white"}}>♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥</li> */}
                    <li><NavLink to="/">home</NavLink></li>
                    <li><NavLink to="/about">about</NavLink></li>
                    <li><NavLink to="/recipes">recipes</NavLink></li>
                    <li><NavLink to="/blog">blog</NavLink></li>
                    <li><NavLink to="/contact">contact</NavLink></li>
                    <li><NavLink to="/search"><FontAwesomeIcon icon={faSearch} /></NavLink></li>
                </Items>
            </Navigation>
        )
    }
}

export default Navbar