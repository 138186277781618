import React from 'react';
import styled from 'styled-components';
import InstagramEmbed from 'react-instagram-embed';

const dotenv = require('dotenv').config()
const client_token = process.env.REACT_APP_APP_ID + '|' + process.env.REACT_APP_CLIENT_TOKEN

const Feed = styled.div`
    margin: auto;
    padding: 0;
    div {
        display: flex;
        flex-direction: row;
    }
    .ig-instagram-link img {
        height: 150px;
        width: 150px;
        padding: 5px 4px 2px 4px;
        object-fit: cover;
    }
    .ig-instagram-link img:hover {
        linear-gradient(
            rgba(220,207,236, 0.45), 
            rgb(220,207,236, 0.5)
          );
    }
    .instagram-count-item {
        display: none;
    }
`

const helpLoading = (e) => {
    console.log('loading')
    console.log(e)
}

const helpErrors = (e) => {
    console.log('error')
    console.log(e)
}

const helpSuccess = (e) => {
    console.log("success")
    console.log(e.error.message)
}

const helpRender = (e) => {
    console.log('render')
    console.log(e)
}

class Instagram extends React.Component {
    render() {
        return(
            <Feed>
                <InstagramEmbed
                    url='https://www.instagram.com/petitenutmeg/'
                    clientAccessToken={client_token}
                    maxWidth={320}
                    hideCaption={false}
                    containerTagName='div'
                    protocol=''
                    injectScript
                    onLoading={(e) => {helpLoading(e)}}
                    onSuccess={(e) => {helpSuccess(e)}}
                    onAfterRender={(e) => {helpRender(e)}}
                    onFailure={(e) => {helpErrors(e)}}
                />
            </Feed>
        )
    }
}

export default Instagram;