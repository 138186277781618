import React from 'react';

class Blog extends React.Component {
    render() {
        return(
            <div>
                <div id="blog-posts">
                    <h1>Blog Posts</h1>
                    <p>Check back here later for new updates!</p>
                </div>
            </div>
        )
    }

}

export default Blog