import React from 'react';

class AboutPage extends React.Component {
    render() {
        return(
            <div>
                <div id="about-page">
                <h2>Hi there, I'm Megan.</h2>
                <p className='text'>I'm a former pastry chef turned software engineer.</p>
                <p className='text'>
                    Cooking, baking, and just being in the kitchen have always been a huge part of my life. I spent a lot of time cooking with my mother and it's always been something that inspired me.
                </p>
                <p className='text'>I'm born and raised in the San Francisco Bay Area and I'm currently still located here.</p>
                <p>I just graduated from Flatiron School and am currently trying to break into the tech industry, looking for full time software engineering positions.</p>
                <p className='text'>During my time as a pastry chef I learned a lot of amazing recipes and I'd like to share what I know with all of you.</p>
                <p className='text'>If you want to chat or connect feel free to message me.</p>
                <h3>Happy baking! ♥</h3>
                </div>
            </div>
        )
    }
}

export default AboutPage;