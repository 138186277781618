import React from 'react';

class RecipeList extends React.Component {

    render() {
        return(
            <div>
                <div id="recipes-page">
                    <h1>Recipes</h1>
                    <p>Check back here for new recipes.</p>
                </div>
            </div>
        )
    }
}

export default RecipeList;